/* eslint camelcase:0 */

import React, { useState, useContext, useEffect, useMemo, createContext } from 'react'
import { useFetch } from '../../fetchOverviewApi'

const StaffingContext = createContext()

export const StaffingContextProvider = ({ children }) => {
  const AVAIBILILITY_RATE_TO_SELECT_FROM = 75
  const [opportunities, setOpportunities] = useState(null)
  const [errors, setErrors] = useState([])
  const [selectedBizDevs, setSelectedBizDevs] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [filteredAvailabilities, setFilteredAvailabilities] = useState(null)
  const [availabilities, setAvailabilities] = useState(null)

  const dateRanges = useMemo(() => {
    const today = new Date()

    const getStartOfMonth = (year, month) => new Date(year, month, 1)
    const getEndOfMonth = (year, month) => new Date(year, month + 1, 0)

    const currentMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth())

    const secondMonthStart = getStartOfMonth(today.getFullYear(), today.getMonth() + 1)
    const secondMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth() + 1)

    const thirdMonthStart = getStartOfMonth(today.getFullYear(), today.getMonth() + 2)
    const thirdMonthEnd = getEndOfMonth(today.getFullYear(), today.getMonth() + 2)

    const currentMonthName = today.toLocaleString('en', { month: 'long' })
    const secondMonthName = secondMonthStart.toLocaleString('en', { month: 'long' })
    const thirdMonthName = thirdMonthStart.toLocaleString('en', { month: 'long' })

    return {
      currentMonth: { name: currentMonthName, startDate: today, endDate: currentMonthEnd },
      secondMonth: { name: secondMonthName, startDate: secondMonthStart, endDate: secondMonthEnd },
      thirdMonth: { name: thirdMonthName, startDate: thirdMonthStart, endDate: thirdMonthEnd },
    }
  }, [])

  const fetchGetOpportunities = async () => {
    setOpportunities(null)

    const response = await useFetch('GET', '/activities/opportunities')

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setOpportunities(response.opportunities)
    }
  }

  const filteredOpportunities = () => {
    if (!opportunities) return {}
    let selectedOpportunities = opportunities

    if (selectedBizDevs.length > 0) {
      selectedOpportunities = selectedOpportunities.filter((opportunity) => selectedBizDevs.includes(opportunity.project_mission_contact))
    }

    if (selectedRoles.length > 0) {
      selectedOpportunities = selectedOpportunities.filter((opportunity) => selectedRoles.includes(opportunity.role_name))
    }

    return Object.fromEntries(selectedOpportunities.map((opportunity, index) => [index, opportunity]))
  }

  const opportunitiesBizDevs = () => {
    if (!opportunities) return []

    return opportunities
      .map((opportunity) => opportunity.project_mission_contact)
      .sort()
      .reduce((accumulator, trigram) => {
        accumulator[trigram] = trigram
        return accumulator
      }, {})
  }

  const opportunitiesRoles = () => {
    if (!opportunities) return []

    return opportunities
      .map((opportunity) => opportunity.role_name)
      .sort()
      .reduce((accumulator, roleName) => {
        accumulator[roleName] = roleName
        return accumulator
      }, {})
  }

  const fetchAvailabilities = async () => {
    setAvailabilities(null)
    let resultAllFetches = []

    const promises = []
    Object.values(dateRanges).forEach((dateRange) => {
      promises.push(useFetch('GET', `/people/availabilities?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`))
    })

    await Promise.all(promises)
    await promises.forEach((promise) => {
      promise.then((response) => {
        if (response.errors) {
          setErrors([...errors, ...response.errors])
        } else {
          resultAllFetches = resultAllFetches.concat(response.people_availabilities)
        }
      })
    })

    setAvailabilities(resultAllFetches)
  }

  const filterAvailabilities = () => {
    const result = []
    const personMap = {}

    let filteredResult = []

    if (availabilities) {
      availabilities.forEach((entry) => {
        const { person, start_date, availability_rate, ...otherKeys } = entry
        const { nickname } = person
        const month = new Date(start_date).toLocaleString('en', { month: 'long' })

        if (!personMap[nickname]) {
          personMap[nickname] = {
            ...otherKeys,
            person,
            start_date,
            availability_rate: { [month]: 0 },
          }
          result.push(personMap[nickname])
        }

        personMap[nickname].availability_rate[month] = entry.availability_rate
      })

      filteredResult = result.filter((availability) => {
        let available = false
        Object.values(availability.availability_rate).forEach((availabilityRate) => {
          available = available || availabilityRate >= AVAIBILILITY_RATE_TO_SELECT_FROM
        })

        return available
      })
    }

    return setFilteredAvailabilities(filteredResult)
  }

  useEffect(() => {
    fetchGetOpportunities()
  }, [])

  useEffect(() => {
    if (availabilities) {
      filterAvailabilities()
    }
  }, [availabilities])

  return (
    <StaffingContext.Provider
      value={{
        errors,
        opportunities,
        fetchGetOpportunities,
        opportunitiesBizDevs,
        opportunitiesRoles,
        setSelectedBizDevs,
        filteredOpportunities,
        setSelectedRoles,
        dateRanges,
        fetchAvailabilities,
        filteredAvailabilities,
        setFilteredAvailabilities,
      }}
    >
      {children}
    </StaffingContext.Provider>
  )
}

export const useStaffingContext = () => useContext(StaffingContext)
