import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@produits-internes-oss/design-system-components'
import { AvailabilitiesStyled } from './Availabilities.styled'
import { useStaffingContext } from '../../context/Staffing/StaffingContext'
import Loader from '../UI/Loader/Loader'

export const Availabilities = () => {
  const { t } = useTranslation()
  const {
    dateRanges,
    filteredAvailabilities,
  } = useStaffingContext()

  if (!filteredAvailabilities || !dateRanges) {
    return <Loader />
  }

  const months = [dateRanges.currentMonth.name, dateRanges.secondMonth.name, dateRanges.thirdMonth.name]

  const availabilities = Object.fromEntries(filteredAvailabilities.map((availability, index) => [index, {
    ...availability,
    month: new Date(availability.start_date).toLocaleDateString('en', { month: 'long' }),
  }]))

  const personFullName = (personData) => {
    const firstName = personData['first_name']
    const lastName = `${personData['last_name'].charAt(0).toUpperCase() + personData['last_name'].slice(1).toLowerCase()}`
    const nickname = personData['nickname'].toUpperCase()

    return `${firstName} ${lastName} (${nickname})`
  }

  const columns = [
    {
      name: 'league',
      title: t('staffingTabs.availabilities.tableHeaders.league'),
      onRender: ({ item }) => t(`staffingTabs.availabilities.league.${item['person']['league']['name'].toLowerCase()}`),
      onSort: ((a, b) => {
        const leagueA = t(`staffingTabs.availabilities.league.${a['person']['league']['name'].toLowerCase()}`)
        const leagueB = t(`staffingTabs.availabilities.league.${b['person']['league']['name'].toLowerCase()}`)
        return leagueA.localeCompare(leagueB)
      }),
    },
    {
      name: 'full_name',
      title: t('staffingTabs.availabilities.tableHeaders.fullName'),
      onRender: ({ item }) => (
        <a
          className="person-link"
          target="blank"
          rel="noreferrer"
          aria-label={`link to ${personFullName(item['person'])}`}
          title={personFullName(item['person'])}
          href={`/timesheet/${item['person']['nickname']}`}
        >
          {personFullName(item['person'])}
        </a>
      ),
      onSort: ((a, b) => personFullName(a['person']).localeCompare(personFullName(b['person']))),
    },
    {
      name: 'main_role',
      title: t('staffingTabs.availabilities.tableHeaders.mainRole'),
      onRender: ({ item }) => (item['person']['main_role'] ? item['person']['main_role']['name'] : '-'),
      onSort: ((a, b) => {
        const roleA = a['person']['main_role']?.name ? a['person']['main_role']['name'] : '-'
        const roleB = b['person']['main_role']?.name ? b['person']['main_role']['name'] : '-'
        return roleA.localeCompare(roleB)
      }),
    },
    {
      name: 'job_name',
      title: t('staffingTabs.availabilities.tableHeaders.jobName'),
      onRender: ({ item }) => item['person']['job_name'],
      onSort: (a, b) => a['person']['job_name'].localeCompare(b['person']['job_name']),
    },
    ...(months.map((month) => ({
      name: `${month}_month`,
      title: t(`staffingTabs.availabilities.tableHeaders.months.${month}`),
      onRender: ({ item }) => (
        <span className={item['availability_rate'][month] >= 75 ? 'highlight-value' : ''}>
          {item['availability_rate'][`${month}`]}
          %
        </span>
      ),
      onSort: ((a, b) => parseFloat(a['availability_rate'][month]) - parseFloat(b['availability_rate'][month])),
    }))),
    {
      name: 'in_idf',
      title: t('staffingTabs.availabilities.tableHeaders.inIdf'),
      onRender: ({ item }) => (item['person']['in_idf'] === null ? '-' : t(`staffingTabs.availabilities.inIdf.${item['person']['in_idf']}`)),
      onSort: ((a, b) => t(`staffingTabs.availabilities.inIdf.${a['person']['in_idf']}`).localeCompare(t(`staffingTabs.availabilities.inIdf.${b['person']['in_idf']}`))),
    },
  ]

  return (
    <AvailabilitiesStyled id="availabilities" role="tabpanel" aria-labelledby="availabilities" tabIndex={0}>
      <h2>{t('staffingTabs.results')}</h2>
      <div className="table-legend">
        <p>{t('staffingTabs.availabilities.tableLegend.0')}</p>
        <p>{t('staffingTabs.availabilities.tableLegend.1')}</p>
        <p>{t('staffingTabs.availabilities.tableLegend.2')}</p>
      </div>
      <Table
        columns={columns}
        data={availabilities}
        sortInitial={{ column: 'league', order: 'asc' }}
      />
    </AvailabilitiesStyled>
  )
}
