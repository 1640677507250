import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, InputSelect } from '@produits-internes-oss/design-system-components'
import { OpportunitiesStyled } from './Opportunities.styled'
import { useStaffingContext } from '../../context/Staffing/StaffingContext'
import { getDateStringFromTimestamp } from '../../helpers/datePickerHelper'
import Loader from '../UI/Loader/Loader'

export const Opportunities = () => {
  const { t } = useTranslation()
  const {
    opportunities,
    opportunitiesBizDevs,
    opportunitiesRoles,
    setSelectedBizDevs,
    setSelectedRoles,
    filteredOpportunities,
  } = useStaffingContext()

  if (opportunities === null) {
    return <Loader />
  }

  const columns = [
    {
      name: 'project_status',
      title: t('staffingTabs.opportunities.tableHeaders.projectStatus'),
      className: 'value-centered',
      onRender: ({ item }) => (
        <div className={`status ${item['project_status'].replaceAll('_', '-')}`}>
          {t(`project.status.${item['project_status']}`)}
        </div>
      ),
      onSort: ((a, b) => t(`project.status.${a['project_status']}`).localeCompare(t(`project.status.${b['project_status']}`))),
    },
    {
      name: 'project_name',
      title: t('staffingTabs.opportunities.tableHeaders.projectName'),
      onRender: ({ item }) => (
        <a
          className="project-link"
          target="blank"
          rel="noreferrer"
          aria-label={`link to ${item['project_name']}`}
          title={item['project_reference']}
          href={`/projects/billable/${item['project_id']}`}
        >
          {item['project_name']}
        </a>
      ),
      onSort: ((a, b) => a['project_name'].localeCompare(b['project_name'])),
    },
    {
      name: 'role_name',
      title: t('staffingTabs.opportunities.tableHeaders.roleName'),
      onRender: ({ item }) => item['role_name'],
      onSort: ((a, b) => a['role_name'].localeCompare(b['role_name'])),
    },
    {
      name: 'nb_days',
      title: t('staffingTabs.opportunities.tableHeaders.nbDays'),
      className: 'value-centered',
      onRender: ({ item }) => item['nb_days'],
      onSort: ((a, b) => parseFloat(a['nb_days']) - parseFloat(b['nb_days'])),
    },
    {
      name: 'profile_name',
      title: t('staffingTabs.opportunities.tableHeaders.profileName'),
      className: 'value-centered',
      onRender: ({ item }) => t(`staffingTabs.opportunities.profiles.${item['profile_name']}`),
      onSort: ((a, b) => t(`staffingTabs.opportunities.profiles.${a['profile_name']}`).localeCompare(t(`staffingTabs.opportunities.profiles.${b['profile_name']}`))),
    },
    {
      name: 'activity_start_date',
      title: t('staffingTabs.opportunities.tableHeaders.activityStartDate'),
      className: 'value-centered',
      onRender: ({ item }) => {
        if (!item['activity_start_date']) {
          return '-'
        }
        return (getDateStringFromTimestamp(new Date(item['activity_start_date'])))
      },
      onSort: ((a, b) => {
        const startDateA = a['activity_start_date'] || 'not_a_date'
        const startDateB = b['activity_start_date'] || 'not_a_date'

        return startDateA.localeCompare(startDateB)
      }),
    },
    {
      name: 'project_location',
      title: t('staffingTabs.opportunities.tableHeaders.projectLocation'),
      onRender: ({ item }) => item['project_location'] || '-',
      onSort: ((a, b) => a['project_location'].localeCompare(b['project_location'])),
    },
    {
      name: 'comment',
      title: t('staffingTabs.opportunities.tableHeaders.activityTitle'),
      onRender: ({ item }) => (item['activity_title'] || '-'),
    },
    {
      name: 'project_mission_contact',
      title: t('staffingTabs.opportunities.tableHeaders.projectMissionContact'),
      className: 'value-centered',
      onRender: ({ item }) => {
        if (!item['project_mission_contact']) {
          return '-'
        }
        return (
          <a
            target="blank"
            rel="noreferrer"
            aria-label={`contact ${item['project_mission_contact']} on mattermost`}
            href={item['project_mission_contact_mattermost']}
            title={`contacter ${item['project_mission_contact']} sur mattermost`}
            className="business-contact-link"
          >
            { item['project_mission_contact']}
          </a>
        )
      },
      onSort: ((a, b) => a['project_mission_contact'].localeCompare(b['project_mission_contact'])),
    },
  ]

  return (
    <OpportunitiesStyled id="opportunities" role="tabpanel" aria-labelledby="opportunities" tabIndex={0}>
      <h2>{t('staffingTabs.filterBy')}</h2>
      <div className="filters">
        <InputSelect
          className="filter"
          skin="light"
          name="bizdev select"
          title={t('staffingTabs.opportunities.filters.title.bizdev')}
          options={opportunitiesBizDevs()}
          localization={{ validation: { text: t('staffingTabs.opportunities.filters.validate'), arialabel: t('staffingTabs.opportunities.filters.validate') } }}
          multiple
          onChange={(value) => {
            setSelectedBizDevs(value)
          }}
        />
        <InputSelect
          className="filter"
          skin="light"
          name="roles select"
          title={t('staffingTabs.opportunities.filters.title.role')}
          options={opportunitiesRoles()}
          localization={{ validation: { text: t('staffingTabs.opportunities.filters.validate'), arialabel: t('staffingTabs.opportunities.filters.validate') } }}
          multiple
          onChange={(value) => {
            setSelectedRoles(value)
          }}
        />
      </div>
      <h2>{t('staffingTabs.results')}</h2>
      <Table
        columns={columns}
        data={filteredOpportunities()}
        sortInitial={{ column: 'activity_start_date', order: 'asc' }}
      />
    </OpportunitiesStyled>
  )
}
