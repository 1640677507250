import styled from 'styled-components'

export const OpportunitiesStyled = styled.div`
h2 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.filters {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.filter {
  max-width: 40rem;
}

.octo.hard .control > button {
  &:focus-visible {
    width: auto;
    border-width: 0.3rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
  }
}

>.octo.hard {
  th {
    padding-right: 1em;
    padding-left: 1em;

    &:focus-visible,
    &:focus-within {
      div > span {
        text-decoration: underline;
      }
    }
  }

  td {
    div.cell {
      a {
        &.business-contact-link,
        &.project-link {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          cursor: pointer;

          &:visited {
            color: #681da8;
          }

          &:hover,
          &:focus-visible,
          &:focus-within {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }

    &.value-centered {
      text-align: center;
    }

    div {
      &.status {
        padding: 0.8rem;
        border-radius: 0.3rem;
      }

      &.lead {
        background-color: ${({ theme }) => theme.colors.betterway.orange[100]};
        color: white;
      }

      &.proposal-in-progress {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      }

      &.proposal-sent {
        background-color: ${({ theme }) => theme.colors.betterway.secondary[70]};
      }

      &.mission-accepted {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-signed {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }

      &.mission-done {
        background-color: ${({ theme }) => theme.colors.betterway.green[100]};
      }
    }
  }
}
`
