import styled from 'styled-components'

export const AvailabilitiesStyled = styled.div`
h2 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

div.table-legend {
  margin-bottom: 2rem;
}

>.octo.hard {
  th {
    padding-right: 1em;
    padding-left: 1em;

    &:focus-visible,
    &:focus-within {
      div > span {
        text-decoration: underline;
      }
    }
  }

  td {
    align-items: left;

    div.cell {
      a {
        &.person-link {
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          cursor: pointer;

          &:visited {
            color: #681da8;
          }

          &:hover,
          &:focus-visible,
          &:focus-within {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }

      .highlight-value {
        padding: 0.4rem;
        border-radius: 0.4rem;
        background-color: ${({ theme }) => theme.colors.betterway.orange[50]};
      }
    }

    &.value-centered {
      text-align: center;
    }
  }
}

`
